<template>
    <div class="data-overview">
        <div></div>
        <div class="refresh-box">
            <el-button type="text" class="refresh-btn" @click="dataDialogVisible=true"><i class="el-icon-question" style="margin-right:2px"></i>数据说明</el-button>
            <div style="margin:0 20px">更新时间：{{time}}</div>
            <el-button type="text" class="refresh-btn" @click="refresh()"><i class="el-icon-refresh" style="margin-right:2px"></i>刷新</el-button>
        </div>
        <div class="module">
            <div class="module-content">
                <div class="title">用户</div>
                <div class="content">
                    <div class="module-box">
                        <img src="../../assets/fangdong.png" />
                        <div class="data-num">
                            <div style="font-size:30px;">{{landlordCount}}</div>
                            <div>房东</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <img src="../../assets/fangke.png" />
                        <div class="data-num">
                            <div style="font-size:30px;">{{tenantCount}}</div>
                            <div>房客</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <img src="../../assets/yonghu (1).png" />
                        <div class="data-num">
                            <div style="font-size:30px;">{{userCount}}</div>
                            <div>用户（含房客）</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="module-content">
                <div class="title">房源</div>
                <div class="content">
                    <div class="module-box">
                        <img src="../../assets/fangyuanshu.png" />
                        <div class="data-num">
                            <div style="font-size:30px;">{{listingCount}}</div>
                            <div>房源数</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <img src="../../assets/loudongshu.png" />
                        <div class="data-num">
                            <div style="font-size:30px;">{{buildingCount}}</div>
                            <div>楼栋数</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <img src="../../assets/fangjianshu.png" />
                        <div class="data-num">
                            <div style="font-size:30px;">{{roomCount}}</div>
                            <div>房间数</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module">
            <div class="module-content">
                <div class="title">平台设备</div>
                <div class="content2">
                    <div class="data-num">
                        <div style="font-size:30px;">{{platFormTotalCount}}</div>
                        <div>平台锁（已绑）</div>
                    </div>
                    <div class="line"></div>
                    <div class="data-num">
                        <div style="font-size:30px;">{{r20Count}}</div>
                        <div>门禁（R20）</div>
                    </div>
                </div>
            </div>
            <div class="module-content">
                <div class="title">售货（易柜）</div>
                <div class="content2">
                    <div class="data-num">
                        <div style="font-size:30px;">{{counterOrderCount}}</div>
                        <div>订单总数（支付）</div>
                    </div>
                    <div class="line"></div>
                    <div class="data-num">
                        <div style="font-size:30px;">{{counterTotalAmount}}</div>
                        <div>订单总金额</div>
                    </div>
                </div>
            </div>
            <div class="module-content">
                <div class="title">财务（租房）</div>
                <div class="content2">
                    <div class="data-num">
                        <div style="font-size:30px;">{{rentOrderCount}}</div>
                        <div>总订单数（账单）</div>
                    </div>
                    <div class="line"></div>
                    <div class="data-num">
                        <div style="font-size:30px;">{{rentTotalAmount}}</div>
                        <div>总收益</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module">
            <div class="module-content">
                <div class="title">智家</div>
                <div class="content">
                    <div style="text-align:center">
                        <div class="circleBox">
                            <el-progress color="#30BAC1" :show-text="false" :width="120" :stroke-width="12" type="circle" :percentage="60">
                            </el-progress>
                            <div class="circleCenter">
                                <div style="font-size:24px;">{{homeTotalCount}}</div>
                            </div>
                        </div>
                        <div>智家锁总数</div>
                    </div>
                    <div class="line"></div>
                    <div style="text-align:center">
                        <div class="circleBox">
                            <el-progress color="#49C86D" :show-text="false" :width="120" :stroke-width="12" type="circle" :percentage="60">
                            </el-progress>
                            <div class="circleCenter">
                                <div style="font-size:24px;">{{s64Count}}</div>
                            </div>
                        </div>
                        <div>S64G（把）</div>
                    </div>
                    <div class="line"></div>
                    <div style="text-align:center">
                        <div class="circleBox">
                            <el-progress color="#FFB016" :show-text="false" :width="120" :stroke-width="12" type="circle" :percentage="60">
                            </el-progress>
                            <div class="circleCenter">
                                <div style="font-size:24px;">{{x3mCount}}</div>
                            </div>
                        </div>
                        <div>X3M（把）</div>
                    </div>
                </div>
            </div>
            <div class="module-content">
                <div class="title">柜锁</div>
                <div class="content">
                    <div style="text-align:center">
                        <div class="circleBox">
                            <el-progress color="#3897F0" :show-text="false" :width="120" :stroke-width="12" type="circle" :percentage="60">
                            </el-progress>
                            <div class="circleCenter">
                                <div style="font-size:24px;">{{counterTotalCount}}</div>
                            </div>
                        </div>
                        <div>柜锁总数</div>
                    </div>
                    <div class="line"></div>
                    <div style="text-align:center">
                        <div class="circleBox">
                            <el-progress color="#FFB016" :show-text="false" :width="120" :stroke-width="12" type="circle" :percentage="60">
                            </el-progress>
                            <div class="circleCenter">
                                <div style="font-size:24px;">{{g1Count}}</div>
                            </div>
                        </div>
                        <div>G1（个）</div>
                    </div>
                    <div class="line"></div>
                    <div style="text-align:center">
                        <div class="circleBox">
                            <el-progress color="#B77CDC" :show-text="false" :width="120" :stroke-width="12" type="circle" :percentage="60">
                            </el-progress>
                            <div class="circleCenter">
                                <div style="font-size:24px;">{{g1kCount}}</div>
                            </div>
                        </div>
                        <div>G1K（个）</div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="数据说明" top="10vh" :visible.sync="dataDialogVisible"  :close-on-click-modal="false">
            <div>
                <div style="line-height:25px;margin-top:-20px">
                    <div class="data-title">用户</div>
                    房东=房东注册提交的总人数（含已审核、未审核总人数）<br>
                    房客=在租或已退租的房客、同住人（含PMS排房、云数平台排房、订房、分享钥匙、授权临时开门等用户）<br>
                    用户（含房客）=云数e家小程序用户端已注册的所有用户（含排房、添加同住人等新增的用户、含已注销和申请注销的用户）<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">房源</div>
                    房源数=房东发布的房源总数（不包含已删除的房源）<br>
                    楼栋数=房东添加的所有房源楼栋总数<br>
                    房间数=房东添加的所有房间（含已下架、未绑锁、已绑锁的所有房间）<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">平台设备</div>
                    平台锁=使用工程软件绑定到房源的门锁，已绑定的平台锁；<br>
                    门禁（R20）=平台绑定的R20门禁总说，含在线、离线的门禁R20;<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">售货（易柜）</div>
                    订单总数（支付）=支付成功的订单总数（含支付成功已退款的订单）<br>
                    订单总金额=支付成功的订单总金额（含支付成功退款的金额）<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">财务（租房）</div>
                    总订单数（账单）=在线订房支付的总订单（账单）数（首期账单+每月自动生成的账单）（仅统计支付成功的订单（账单））<br>
                    总收益=在线订房支付成功的订单（账单）总金额（含租金、押金、物业费、水电费）（含已退款的金额）<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">智家</div>
                    智家锁总数=智家模块下，用户绑定的所有门锁总数；（不包含已解绑的门锁）<br>
                    S64G(把)=智家模块下，用户绑定的S64G锁总数；（不包含已解绑的S64G）<br>
                    X3M(把)=智家模块下，用户绑定的X3M锁总数；（不包含已解绑的X3M）<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">柜锁</div>
                    柜锁总数=用户绑定的所有柜锁总数，不包含已解绑的柜锁；<br>
                    G1(个)=用户绑定的G1柜锁总数，不包含已解绑的G1柜锁；<br>
                    G1K（个）=用户绑定的G1K总数，不包含已解绑的G1K柜锁；<br>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            time: '',
            landlordCount: '',
            tenantCount: '',
            userCount: '',
            listingCount: '',
            buildingCount: '',
            roomCount: '',
            platFormTotalCount: '',
            r20Count: '',
            counterOrderCount: '',
            counterTotalAmount: '',
            rentOrderCount: '',
            rentTotalAmount: '',
            homeTotalCount: '',
            s64Count: '',
            x3mCount: '',
            counterTotalCount: '',
            g1Count: '',
            g1kCount: '',
            dataDialogVisible: false,
        }
    },
    activated() {
        this.getData();
        this.time = this.getNowTime();
    },
    methods: {
        getData(){
            this.post("statistic-service/statistic/getDashboardStatistics").then(res=>{
                if(res){
                    this.landlordCount = res.landlordCount;
                    this.tenantCount = res.tenantCount;
                    this.userCount = res.userCount;
                    this.listingCount = res.listingCount;
                    this.buildingCount = res.buildingCount;
                    this.roomCount = res.roomCount;
                    this.platFormTotalCount = res.platFormTotalCount;
                    this.r20Count = res.r20Count;
                    this.counterOrderCount = res.counterOrderCount;
                    this.counterTotalAmount = res.counterTotalAmount;
                    this.rentOrderCount = res.rentOrderCount;
                    this.rentTotalAmount = res.rentTotalAmount;
                    this.homeTotalCount = res.homeTotalCount;
                    this.s64Count = res.s64Count;
                    this.x3mCount = res.x3mCount;
                    this.counterTotalCount = res.counterTotalCount;
                    this.g1Count = res.g1Count;
                    this.g1kCount = res.g1kCount;
                }
            })
        },
        getNowTime(){
            var date = new Date();
            var sign2 = ":";
            var year = date.getFullYear() // 年
            var month = date.getMonth() + 1; // 月
            var day = date.getDate(); // 日
            var hour = date.getHours(); // 时
            var minutes = date.getMinutes(); // 分
            var seconds = date.getSeconds() //秒
            var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
            var week = weekArr[date.getDay()];
            // 给一位数的数据前面加 “0”
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (day >= 0 && day <= 9) {
                day = "0" + day;
            }
            if (hour >= 0 && hour <= 9) {
                hour = "0" + hour;
            }
            if (minutes >= 0 && minutes <= 9) {
                minutes = "0" + minutes;
            }
            if (seconds >= 0 && seconds <= 9) {
                seconds = "0" + seconds;
            }
            return year + "/" + month + "/" + day + " " + hour + sign2 + minutes + sign2 + seconds;
        },
        refresh(){
            this.time = this.getNowTime();
            this.post("statistic-service/statistic/updateStatisticsTables").then(res=>{
                if(res){
                    this.getData() 
                }
            })
        }
    }
}
</script>
<style lang="scss" scope>
.data-overview{
    background: #F6F6F6;
    margin: -10px -20px;
    padding: 35px;
    font-size: 14px;
    .module{
        display: flex;
        justify-content: space-around;
        margin-bottom: 30px;
    }
    .refresh-box{
        height: 16px;
        line-height: 16px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        margin-right: 20px;
    }
    .refresh-btn{
        color: #333;
        padding: 0;
        margin-left: 3px;
    }
    .title{
        border-left: 4px solid #30BAC1;
        height: 16px;
        line-height: 16px;
        padding-left: 7px;
        background: #FFFFFF;
        font-size: 16px;
    }
    .content{
        background: #FFFFFF;
        width: 794px;
        height: 182px;
        display: flex; 
        align-items: center;
        justify-content: space-around;
    }
    .content2{
        background: #FFFFFF;
        width: 520px;
        height: 182px;
        display: flex; 
        align-items: center;
        justify-content: space-around;
        text-align: center;
    }
    .line{
        background: #DCDFE6;
        width: 1px;
        height: 93px;
    }
    .module-box{
        display: flex; 
        img{
            width: 56px;
            height: 56px;
            margin-right: 15px;
        }
    }
    .circleBox {
        position: relative;
        text-align: center;
        width: 200px;
        margin-bottom: 10px;
    }

    .circleCenter {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .module-content {
        padding: 10px 0 0 10px;
        background: #FFFFFF;
    }
    .data-title {
        font-weight: bold;
        margin: 10px 0 0;
    }
}
</style>