var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-overview" },
    [
      _c("div"),
      _c(
        "div",
        { staticClass: "refresh-box" },
        [
          _c(
            "el-button",
            {
              staticClass: "refresh-btn",
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  _vm.dataDialogVisible = true
                }
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-question",
                staticStyle: { "margin-right": "2px" }
              }),
              _vm._v("数据说明")
            ]
          ),
          _c("div", { staticStyle: { margin: "0 20px" } }, [
            _vm._v("更新时间：" + _vm._s(_vm.time))
          ]),
          _c(
            "el-button",
            {
              staticClass: "refresh-btn",
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  _vm.refresh()
                }
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-refresh",
                staticStyle: { "margin-right": "2px" }
              }),
              _vm._v("刷新")
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "module" }, [
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("用户")]),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "module-box" }, [
              _c("img", {
                attrs: { src: require("../../assets/fangdong.png") }
              }),
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.landlordCount))
                ]),
                _c("div", [_vm._v("房东")])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("img", { attrs: { src: require("../../assets/fangke.png") } }),
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.tenantCount))
                ]),
                _c("div", [_vm._v("房客")])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("img", {
                attrs: { src: require("../../assets/yonghu (1).png") }
              }),
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.userCount))
                ]),
                _c("div", [_vm._v("用户（含房客）")])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("房源")]),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "module-box" }, [
              _c("img", {
                attrs: { src: require("../../assets/fangyuanshu.png") }
              }),
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.listingCount))
                ]),
                _c("div", [_vm._v("房源数")])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("img", {
                attrs: { src: require("../../assets/loudongshu.png") }
              }),
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.buildingCount))
                ]),
                _c("div", [_vm._v("楼栋数")])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("img", {
                attrs: { src: require("../../assets/fangjianshu.png") }
              }),
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.roomCount))
                ]),
                _c("div", [_vm._v("房间数")])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "module" }, [
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("平台设备")]),
          _c("div", { staticClass: "content2" }, [
            _c("div", { staticClass: "data-num" }, [
              _c("div", { staticStyle: { "font-size": "30px" } }, [
                _vm._v(_vm._s(_vm.platFormTotalCount))
              ]),
              _c("div", [_vm._v("平台锁（已绑）")])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "data-num" }, [
              _c("div", { staticStyle: { "font-size": "30px" } }, [
                _vm._v(_vm._s(_vm.r20Count))
              ]),
              _c("div", [_vm._v("门禁（R20）")])
            ])
          ])
        ]),
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("售货（易柜）")]),
          _c("div", { staticClass: "content2" }, [
            _c("div", { staticClass: "data-num" }, [
              _c("div", { staticStyle: { "font-size": "30px" } }, [
                _vm._v(_vm._s(_vm.counterOrderCount))
              ]),
              _c("div", [_vm._v("订单总数（支付）")])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "data-num" }, [
              _c("div", { staticStyle: { "font-size": "30px" } }, [
                _vm._v(_vm._s(_vm.counterTotalAmount))
              ]),
              _c("div", [_vm._v("订单总金额")])
            ])
          ])
        ]),
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("财务（租房）")]),
          _c("div", { staticClass: "content2" }, [
            _c("div", { staticClass: "data-num" }, [
              _c("div", { staticStyle: { "font-size": "30px" } }, [
                _vm._v(_vm._s(_vm.rentOrderCount))
              ]),
              _c("div", [_vm._v("总订单数（账单）")])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "data-num" }, [
              _c("div", { staticStyle: { "font-size": "30px" } }, [
                _vm._v(_vm._s(_vm.rentTotalAmount))
              ]),
              _c("div", [_vm._v("总收益")])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "module" }, [
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("智家")]),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _c(
                "div",
                { staticClass: "circleBox" },
                [
                  _c("el-progress", {
                    attrs: {
                      color: "#30BAC1",
                      "show-text": false,
                      width: 120,
                      "stroke-width": 12,
                      type: "circle",
                      percentage: 60
                    }
                  }),
                  _c("div", { staticClass: "circleCenter" }, [
                    _c("div", { staticStyle: { "font-size": "24px" } }, [
                      _vm._v(_vm._s(_vm.homeTotalCount))
                    ])
                  ])
                ],
                1
              ),
              _c("div", [_vm._v("智家锁总数")])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _c(
                "div",
                { staticClass: "circleBox" },
                [
                  _c("el-progress", {
                    attrs: {
                      color: "#49C86D",
                      "show-text": false,
                      width: 120,
                      "stroke-width": 12,
                      type: "circle",
                      percentage: 60
                    }
                  }),
                  _c("div", { staticClass: "circleCenter" }, [
                    _c("div", { staticStyle: { "font-size": "24px" } }, [
                      _vm._v(_vm._s(_vm.s64Count))
                    ])
                  ])
                ],
                1
              ),
              _c("div", [_vm._v("S64G（把）")])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _c(
                "div",
                { staticClass: "circleBox" },
                [
                  _c("el-progress", {
                    attrs: {
                      color: "#FFB016",
                      "show-text": false,
                      width: 120,
                      "stroke-width": 12,
                      type: "circle",
                      percentage: 60
                    }
                  }),
                  _c("div", { staticClass: "circleCenter" }, [
                    _c("div", { staticStyle: { "font-size": "24px" } }, [
                      _vm._v(_vm._s(_vm.x3mCount))
                    ])
                  ])
                ],
                1
              ),
              _c("div", [_vm._v("X3M（把）")])
            ])
          ])
        ]),
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("柜锁")]),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _c(
                "div",
                { staticClass: "circleBox" },
                [
                  _c("el-progress", {
                    attrs: {
                      color: "#3897F0",
                      "show-text": false,
                      width: 120,
                      "stroke-width": 12,
                      type: "circle",
                      percentage: 60
                    }
                  }),
                  _c("div", { staticClass: "circleCenter" }, [
                    _c("div", { staticStyle: { "font-size": "24px" } }, [
                      _vm._v(_vm._s(_vm.counterTotalCount))
                    ])
                  ])
                ],
                1
              ),
              _c("div", [_vm._v("柜锁总数")])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _c(
                "div",
                { staticClass: "circleBox" },
                [
                  _c("el-progress", {
                    attrs: {
                      color: "#FFB016",
                      "show-text": false,
                      width: 120,
                      "stroke-width": 12,
                      type: "circle",
                      percentage: 60
                    }
                  }),
                  _c("div", { staticClass: "circleCenter" }, [
                    _c("div", { staticStyle: { "font-size": "24px" } }, [
                      _vm._v(_vm._s(_vm.g1Count))
                    ])
                  ])
                ],
                1
              ),
              _c("div", [_vm._v("G1（个）")])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _c(
                "div",
                { staticClass: "circleBox" },
                [
                  _c("el-progress", {
                    attrs: {
                      color: "#B77CDC",
                      "show-text": false,
                      width: 120,
                      "stroke-width": 12,
                      type: "circle",
                      percentage: 60
                    }
                  }),
                  _c("div", { staticClass: "circleCenter" }, [
                    _c("div", { staticStyle: { "font-size": "24px" } }, [
                      _vm._v(_vm._s(_vm.g1kCount))
                    ])
                  ])
                ],
                1
              ),
              _c("div", [_vm._v("G1K（个）")])
            ])
          ])
        ])
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "数据说明",
            top: "10vh",
            visible: _vm.dataDialogVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dataDialogVisible = $event
            }
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticStyle: { "line-height": "25px", "margin-top": "-20px" } },
              [
                _c("div", { staticClass: "data-title" }, [_vm._v("用户")]),
                _vm._v(
                  "\n                房东=房东注册提交的总人数（含已审核、未审核总人数）"
                ),
                _c("br"),
                _vm._v(
                  "\n                房客=在租或已退租的房客、同住人（含PMS排房、云数平台排房、订房、分享钥匙、授权临时开门等用户）"
                ),
                _c("br"),
                _vm._v(
                  "\n                用户（含房客）=云数e家小程序用户端已注册的所有用户（含排房、添加同住人等新增的用户、含已注销和申请注销的用户）"
                ),
                _c("br")
              ]
            ),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [_vm._v("房源")]),
              _vm._v(
                "\n                房源数=房东发布的房源总数（不包含已删除的房源）"
              ),
              _c("br"),
              _vm._v("\n                楼栋数=房东添加的所有房源楼栋总数"),
              _c("br"),
              _vm._v(
                "\n                房间数=房东添加的所有房间（含已下架、未绑锁、已绑锁的所有房间）"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [_vm._v("平台设备")]),
              _vm._v(
                "\n                平台锁=使用工程软件绑定到房源的门锁，已绑定的平台锁；"
              ),
              _c("br"),
              _vm._v(
                "\n                门禁（R20）=平台绑定的R20门禁总说，含在线、离线的门禁R20;"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("售货（易柜）")
              ]),
              _vm._v(
                "\n                订单总数（支付）=支付成功的订单总数（含支付成功已退款的订单）"
              ),
              _c("br"),
              _vm._v(
                "\n                订单总金额=支付成功的订单总金额（含支付成功退款的金额）"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("财务（租房）")
              ]),
              _vm._v(
                "\n                总订单数（账单）=在线订房支付的总订单（账单）数（首期账单+每月自动生成的账单）（仅统计支付成功的订单（账单））"
              ),
              _c("br"),
              _vm._v(
                "\n                总收益=在线订房支付成功的订单（账单）总金额（含租金、押金、物业费、水电费）（含已退款的金额）"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [_vm._v("智家")]),
              _vm._v(
                "\n                智家锁总数=智家模块下，用户绑定的所有门锁总数；（不包含已解绑的门锁）"
              ),
              _c("br"),
              _vm._v(
                "\n                S64G(把)=智家模块下，用户绑定的S64G锁总数；（不包含已解绑的S64G）"
              ),
              _c("br"),
              _vm._v(
                "\n                X3M(把)=智家模块下，用户绑定的X3M锁总数；（不包含已解绑的X3M）"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [_vm._v("柜锁")]),
              _vm._v(
                "\n                柜锁总数=用户绑定的所有柜锁总数，不包含已解绑的柜锁；"
              ),
              _c("br"),
              _vm._v(
                "\n                G1(个)=用户绑定的G1柜锁总数，不包含已解绑的G1柜锁；"
              ),
              _c("br"),
              _vm._v(
                "\n                G1K（个）=用户绑定的G1K总数，不包含已解绑的G1K柜锁；"
              ),
              _c("br")
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }